import React from 'react'
import { useSelector } from 'react-redux'
import cookies from 'react-cookies'
import { pushGTMEvent, returnCreditType } from '../functions'
import GlobalData from '../../globalData.json'

const useGMT = () => {
  let user = cookies.load('user')
  const profile = useSelector(state => state.user.profileData)

  const contactid = profile?.id || ''
  const contacttype = user?.role?.name || ''
  const loginstatus = user ? 'loggedin' : 'loggedout'
  const preregistrationid = user?.user?.id || ''

  let candidatePrices = GlobalData?.CandidateCatalog?.prices?.length
    ? GlobalData?.CandidateCatalog?.prices?.filter(item => !item.isDiscontinued)
    : []
  let employerPrices = GlobalData.EmployerCatalog?.prices?.length
    ? GlobalData?.EmployerCatalog?.prices?.filter(item => !item.isDiscontinued)
    : []

  const mapToItem = (lineItem, itemCategory) => {
    const item = {
      item_id: lineItem.price.priceId,
      item_name: lineItem.price.nickname ?? lineItem.price.description,
      item_category: lineItem.price.recurring
        ? 'Subscription'
        : 'Single Purchase',
      price: lineItem.price.amount,
      quantity: lineItem.quantity || lineItem?.price.quantity || 1,
    }

    if (item.item_category == 'Single Purchase') {
      item.item_category2 = lineItem.price.description.includes('Featured')
        ? 'Featured Job Credit'
        : 'Job Posting Credit'

      return item
    }

    item.item_category2 = itemCategory

    if (item.item_category2 == 'Member') {
      item.item_category3 = lineItem.name
      return item
    }

    item.item_category3 = item.item_name.includes('Resume')
      ? 'Resume Search'
      : item.item_name.includes('Logo')
      ? 'Featured Logo'
      : item.item_name.includes('Automated')
      ? 'Job Scraping'
      : 'Job Posting'
    item.item_category4 = item.item_name.toLowerCase().includes('annual')
      ? 'Annual'
      : 'Monthly'

    return item
  }

  const getCandiateItemList = priceId => {
    const activeItem = candidatePrices.find(
      item => item?.price?.priceId === priceId
    )

    return [mapToItem(activeItem, 'Member')]
  }

  const getEmpItemList = (priceId, addOns) => {
    const itemList = []
    if (priceId) {
      const activeItem = employerPrices.find(
        item => item?.price?.priceId === priceId
      )
      itemList.push(mapToItem(activeItem, 'Job Poster'))
    }

    if (addOns && addOns.length > 0) {
      for (const addonItem of addOns) {
        itemList.push(
          mapToItem(
            {
              price: addonItem,
            },
            'Job Poster'
          )
        )
      }
    }

    return itemList
  }

  const pushLoginEvent = payload => {
    pushGTMEvent({
      event_name: 'login',
      contactid,
      contacttype,
      preregistrationid,
      ...payload,
    })
  }

  const pushRegisterEvent = newsletterSubscribe => {
    pushGTMEvent({
      event_name: 'registration',
      preregistrationid,
      newsletter_subscription_check: newsletterSubscribe,
    })
  }

  const pushViewJobEvent = payload => {
    pushGTMEvent({
      event_name: 'job_view',
      contactid,
      contacttype,
      loginstatus,
      preregistrationid,
      ...payload,
    })
  }

  const pushApplyInternalJobEvent = payload => {
    pushGTMEvent({
      event_name: 'job_apply',
      applyType: 'Internal',
      contactid,
      preregistrationid,
      ...payload,
    })
  }

  const pushApplyExternalJobEvent = payload => {
    pushGTMEvent({
      event_name: 'job_apply',
      applyType: 'External',
      contactid,
      preregistrationid,
      ...payload,
    })
  }

  const pushSearchJobEvent = payload => {
    pushGTMEvent({
      event_name: 'job_search',
      contactid,
      contacttype,
      loginstatus,
      preregistrationid,
      ...payload,
    })
  }

  const pushSearchResumeEvent = payload => {
    pushGTMEvent({
      event_name: 'resume_search',
      contactid,
      contacttype,
      loginstatus,
      preregistrationid,
      ...payload,
    })
  }

  const pushUploadResumeEvent = payload => {
    pushGTMEvent({
      event_name: 'resume_upload',
      contactid,
      preregistrationid,
      ...payload,
    })
  }

  const pushFilterApplyEvent = payload => {
    pushGTMEvent({
      event_name: 'filter_apply',
      contactid,
      contacttype,
      loginstatus,
      preregistrationid,
      ...payload,
    })
  }

  const pushSignUpJobAlertEvent = payload => {
    pushGTMEvent({
      event_name: 'job_alert_sign_up',
      contacttype,
      preregistrationid,
      ...payload,
    })
  }

  const pushContactUsEvent = () => {
    pushGTMEvent({
      event_name: 'contact_us_form_submission',
      contactid,
      contacttype,
      loginstatus,
      preregistrationid,
    })
  }

  const pushSubscribeToNewsletterEvent = payload => {
    pushGTMEvent({
      event_name: 'newsletter_sign_up',
      contacttype: user ? contacttype : 'loggedout',
      loginstatus,
      ...payload,
    })
  }

  const pushPostJobEvent = payload => {
    pushGTMEvent({
      event_name: 'job_posting',
      contactid,
      contacttype,
      loginstatus,
      preregistrationid,
      ...payload,
    })
  }

  const pushSignUpSalarySurveyEvent = payload => {
    pushGTMEvent({
      event_name: 'salary_survey_sign_up',
      contacttype,
      loginstatus,
      preregistrationid,
      ...payload,
    })
  }

  const pushCancelSubscriptionEvent = subscriptionPlan => {
    pushGTMEvent({
      event_name: 'subscription_cancellation',
      subscription_plan: subscriptionPlan,
      'recurringCancellation.cancelReason': 'High prices',
      preregistrationid,
    })
  }

  const pushCandidateViewItemEvent = () => {
    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'view_item_list',
      loginstatus,
      preregistrationid,
      ecommerce: {
        items: candidatePrices.map(item => mapToItem(item, 'Member')),
      },
      _clear: true,
    })
  }

  const pushEmployerViewItemEvent = (item_list_id, priceId) => {
    if (item_list_id === '2') {
      pushGTMEvent({
        event: 'GTMenhancedEcommerce',
        event_name: 'view_item_list',
        loginstatus,
        preregistrationid,
        ecommerce: {
          items: employerPrices.map(item => mapToItem(item, 'Job Poster')),
        },
        _clear: true,
      })
    } else if (item_list_id === '3') {
      const activePrice = employerPrices.find(
        item => item.price.priceId === priceId
      )
      pushGTMEvent({
        event: 'GTMenhancedEcommerce',
        event_name: 'view_item_list',
        loginstatus,
        preregistrationid,
        ecommerce: {
          items: getEmpItemList(null, activePrice.addOns || []),
        },
        _clear: true,
      })
    }
  }

  const pushCandidateSelectItemEvent = priceId => {
    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'view_item',
      loginstatus,
      preregistrationid,
      ecommerce: {
        items: getCandiateItemList(priceId),
      },
      _clear: true,
    })
  }

  const pushEmployerSelectAddonEvent = addOns => {
    const filteredAddOns = addOns.filter(
      item =>
        (item.recurring && item.is_selected) ||
        (!item.recurring && item.quantity)
    )

    const itemList = []

    if (filteredAddOns && filteredAddOns.length > 0) {
      for (const addonItem of filteredAddOns) {
        itemList.push(
          mapToItem(
            {
              price: { ...addonItem, quantity: 1 },
            },
            'Job Poster'
          )
        )
      }
    }

    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'view_item',
      loginstatus,
      preregistrationid,
      ecommerce: {
        items: itemList,
      },
      _clear: true,
    })
  }

  const pushEmployerSelectItemEvent = priceId => {
    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'view_item',
      loginstatus,
      preregistrationid,
      ecommerce: {
        items: getEmpItemList(priceId, null),
      },
      _clear: true,
    })
  }

  const pushCandidateAddToCartEvent = (priceId, priceData) => {
    const ecommerceItem = priceData
      ? priceData
      : candidatePrices.find(item => item.price?.priceId === priceId)

    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'add_to_cart',
      loginstatus,
      preregistrationid,
      ecommerce: {
        currency: 'USD',
        value: ecommerceItem.price.amount,
        items: getCandiateItemList(priceId),
      },
    })
  }

  const pushEmployerAddToCartSubscriptionEvent = priceId => {
    const activePrice = employerPrices.find(
      item => item.price.priceId === priceId
    )

    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'add_to_cart',
      loginstatus,
      preregistrationid,
      ecommerce: {
        currency: 'USD',
        value: activePrice.price.amount,
        items: getEmpItemList(priceId, null),
      },
    })
  }

  const pushEmployerAddToCartAddonEvent = addOns => {
    const filteredAddons = addOns.filter(
      item =>
        (item.recurring && item.is_selected) ||
        (!item.recurring && item.quantity)
    )

    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'add_to_cart',
      loginstatus,
      preregistrationid,
      ecommerce: {
        currency: 'USD',
        value: filteredAddons.reduce(
          (acc, cur) => acc + cur.amount * (cur.quantity || 1),
          0
        ),
        items: getEmpItemList(null, filteredAddons),
      },
    })
  }

  const pushCandidateCheckoutEvent = priceId => {
    const ecommerceItem = candidatePrices.find(
      item => item.price.priceId === priceId
    )

    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'begin_checkout',
      loginstatus,
      preregistrationid,
      ecommerce: {
        currency: 'USD',
        value: ecommerceItem.price.amount,
        items: getCandiateItemList(priceId),
      },
    })
  }

  const pushEmployerCheckoutEvent = (priceId, addOns) => {
    const ecommerceItem = employerPrices.find(
      item => item.price.priceId === priceId
    )

    const filteredAddOns = addOns.filter(
      item =>
        (item.recurring && item.is_selected) ||
        (!item.recurring && item.quantity)
    )

    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'begin_checkout',
      loginstatus,
      preregistrationid,
      ecommerce: {
        currency: 'USD',
        value:
          (ecommerceItem?.price?.amount || 0) +
          filteredAddOns.reduce(
            (acc, cur) => acc + cur.amount * (cur.quantity || 1),
            0
          ),
        items: getEmpItemList(priceId, filteredAddOns),
      },
    })
  }

  const pushCandidatePurchaseEvent = (priceId, transactionId) => {
    const ecommerceItem = candidatePrices.find(
      item => item.price.priceId === priceId
    )

    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'purchase',
      preregistrationid,
      contactid,
      contacttype,
      ecommerce: {
        transaction_id: transactionId,
        currency: 'USD',
        value: ecommerceItem.price.amount,
        items: getCandiateItemList(priceId),
      },
    })
  }

  const pushEmployerPurchaseEvent = (transactionId, subscription, addOns) => {
    const ecommerceItem = employerPrices.find(
      item => item.price.priceId === subscription?.priceId
    )

    const filteredAddOns = (addOns || []).filter(
      item =>
        (item.recurring && item.is_selected) ||
        (!item.recurring && item.quantity)
    )

    pushGTMEvent({
      event: 'GTMenhancedEcommerce',
      event_name: 'purchase',
      loginstatus,
      preregistrationid,
      ecommerce: {
        transaction_id: transactionId,
        currency: 'USD',
        value:
          (ecommerceItem?.price?.amount || 0) +
          filteredAddOns.reduce(
            (acc, cur) => acc + cur.amount * (cur.quantity || 1),
            0
          ),
        items: getEmpItemList(subscription?.priceId, filteredAddOns),
      },
    })
  }

  const getResumeSearchCriteriaStr = obj => {
    const resultObj = {}
    if (obj.willingTravelUpto && obj.willingTravelUpto?.length) {
      resultObj['TravelPercent'] = obj.willingTravelUpto.join(',')
    } else {
      resultObj['TravelPercent'] = 'all'
    }

    if (obj.relocate) {
      resultObj['Relocate'] = 'True'
    } else {
      resultObj['Relocate'] = 'False'
    }

    if (obj.industries && obj.industries?.length) {
      resultObj['Cat'] = obj.industries.join(',')
    } else {
      resultObj['Cat'] = 'all'
    }

    if (obj.functions && obj.functions.length) {
      resultObj['Fun'] = obj.functions.join(',')
    } else {
      resultObj['Fun'] = 'all'
    }

    if (obj.medicalExp && obj.medicalExp.length) {
      resultObj['MedEx'] = obj.medicalExp.join(',')
    } else {
      resultObj['MedEx'] = 'all'
    }

    if (obj.totalExp && obj.totalExp.length) {
      resultObj['TotalEx'] = obj.totalExp.join(',')
    } else {
      resultObj['TotalEx'] = 'all'
    }

    if (obj.distance) {
      resultObj['Rarius'] = obj.distance
    }

    if (obj.city || obj.state || obj.country) {
      resultObj['Loc'] = `${obj.city},${obj.state},${obj.country}`
    } else {
      resultObj['Loc'] = 'all'
    }

    if (obj.keyword) {
      resultObj['Kwd'] = obj.keyword
    }

    return Object.entries(resultObj)
      .map(item => `${item[0]}=${item[1]}`)
      .join(', ')
  }

  const getJobSearchCriteriaStr = obj => {
    const resultObj = {}
    if (obj.travel_percentage && obj.travel_percentage?.length) {
      resultObj['TravelPercent'] = obj.travel_percentage.join(',')
    } else {
      resultObj['TravelPercent'] = 'all'
    }

    if (obj.max_compensation) {
      resultObj['Comp'] = `${obj.min_compensation || 0},${obj.max_compensation}`
    } else {
      resultObj['Comp'] = 'all'
    }

    if (obj.keyword) {
      resultObj['Kwd'] = obj.keyword
    }

    if (obj.distance) {
      resultObj['Distance'] = obj.distance
    }

    if (obj.field_based) {
      resultObj['Field'] = 'True'
    } else {
      resultObj['Field'] = 'False'
    }

    if (obj.remote_enabled) {
      resultObj['Remote'] = 'True'
    } else {
      resultObj['Remote'] = 'False'
    }

    if (obj.employer_type && obj.employer_type.length) {
      resultObj['EmpType'] = obj.employer_type.join(',')
    } else {
      resultObj['EmpType'] = 'all'
    }

    if (obj.product_category && obj.product_category.length) {
      resultObj['Cat'] = obj.product_category.join(',')
    } else {
      resultObj['Cat'] = 'all'
    }

    if (obj.primary_function && obj.primary_function.length) {
      resultObj['Fun'] = obj.primary_function.join(',')
    } else {
      resultObj['Fun'] = 'all'
    }

    if (obj.compensation_type && obj.compensation_type.length) {
      resultObj['CompType'] = obj.compensation_type.join(',')
    } else {
      resultObj['CompType'] = 'all'
    }

    if (obj.city || obj.state || obj.country) {
      resultObj['Loc'] = `${obj.city},${obj.state},${obj.country}`
    } else {
      resultObj['Loc'] = 'all'
    }

    return Object.entries(resultObj)
      .map(item => `${item[0]}=${item[1]}`)
      .join(', ')
  }

  return {
    pushLoginEvent,
    pushRegisterEvent,
    pushViewJobEvent,
    pushApplyInternalJobEvent,
    pushApplyExternalJobEvent,
    pushFilterApplyEvent,
    pushSearchJobEvent,
    pushSearchResumeEvent,
    pushUploadResumeEvent,
    pushSignUpJobAlertEvent,
    pushSignUpSalarySurveyEvent,
    pushContactUsEvent,
    pushSubscribeToNewsletterEvent,
    pushPostJobEvent,
    pushCancelSubscriptionEvent,
    pushCandidateViewItemEvent,
    pushEmployerViewItemEvent,
    pushCandidateSelectItemEvent,
    pushEmployerSelectItemEvent,
    pushEmployerSelectAddonEvent,
    pushCandidateAddToCartEvent,
    pushEmployerAddToCartSubscriptionEvent,
    pushEmployerAddToCartAddonEvent,
    pushCandidateCheckoutEvent,
    pushEmployerCheckoutEvent,
    pushCandidatePurchaseEvent,
    pushEmployerPurchaseEvent,
    getJobSearchCriteriaStr,
    getResumeSearchCriteriaStr,
  }
}

export default useGMT
